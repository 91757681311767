.bg-image-custom {
  background-image: url("../../../public/assets/images/profile.png");
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
}

.size-custom {
  width: 20% !important;
}

@media only screen and (max-width: 425px) {
  .bg-image-custom {
    background-image: url("../../../public/assets/images/profile_mobile.png");
    background-size: cover;
    background-position: center;
    background-repeat: repeat-y;
    height: 100vh;
  }
  .size-custom {
    width: 80% !important;
  }
}
